.aboutWrapperInner {
  flex-direction: column;
  width: 1100px;
  height: auto;
}

.aboutHeadingContainer {
  text-align: center;
  font-size: 1.8rem;
  width: 40%;
  text-align: center;
  margin: 15px;
}

.aboutHeading {
  margin: 75px 10px 30px 10px;
  font-size: 4rem;
  text-align: center;
}

.aboutSubHeading {
  margin: 10px 10px 30px;
  font-size: 1.5rem;
  text-align: center;
}


.aboutList {
  width: 100%;
  height: 20%;
  align-items: flex-start;
  justify-content: space-between;
}

.aboutDiv {
  flex-direction: column;
  padding: 20px;
  margin: 0 5px;
  height: 15rem;
  background: #f6f7f8;
  border-radius: 0.5rem;
}

.aboutField {
  font-size: 1.8rem;
  font-weight: 600;
  padding: 0 40px 0 10px;
}

.aboutDes {
  font-size: 1.2rem;
  color: rgba(52, 53, 56, 0.6);
  padding: 10px 32.5px 10px 10px;
}

.aboutMain {
  height: 30%;
  width: 100%;
  margin-bottom: 3rem;
  display: flex;
  justify-content: space-between;
}

.aboutMainImgCont {
  width: 49%;
  height: 100%;
  border-radius: 5px;
}

.aboutMainImg {
  height: 75%;
  width: 100%;
  object-fit: cover;
}

.aboutMainDes {
  width: 49%;
  height: 100%;
}

.aboutMainDes p {
  opacity: 0.8;
  margin-top: 20px;
}

.aboutMainDes ul {
  opacity: 0.8;
  margin-top: 20px;
}

.aboutMainDes ul li {
  display: flex;
  align-items: center;
}

.aboutMainDes ul li svg {
  margin-right: 10px;
}

.shopBtn {
  color: white;
  font-size: 1rem;
  font-weight: bold;
  background: #0aad0a;
  padding: 15px;
  width: 290px;
  border-radius: 1rem;
  outline-style: none;
  border: none;
  margin: 50px 0;
  cursor: pointer;
}
.shopBtn:hover {
  background: #039b03;
}

.aspect {
  aspect-ratio: 2 / 1;
}

@media (max-width: 1200px) {
  .aboutHeadingContainer {
    width: 50%;
  }

  .aboutWrapperInner {
    width: 90%;
  }
  .aboutList {
    flex-direction: column;
  }
  .aboutDiv {
    width: 100%;
    margin: 5px 0;
  }
  .aboutDes {
    font-size: 1rem;
  }
}

@media (max-width: 900px) {
  .aboutHeadingContainer {
    width: 75%;
    font-size: 1.5rem;
  }

  .aboutHeading {
    font-size: 3.5rem;
  }

  .aboutSubHeading {
    font-size: 1.2rem;
  }

  .aboutDes {
    display: none;
  }
  .open {
    display: flex;
  }
  .aboutMain {
    display: flex;
    flex-direction: column;
  }

  .aboutMainImgCont, .aboutMain .aboutMainDes, .aboutMain button {
    width: 100%;
  }

  .aboutMainImgCont {
    margin-bottom: 3rem;
  }
}

@media (max-width: 750px) {
  .aboutImg {
    height: 100%;
  }
}

@media (max-width: 500px) {
  .aboutHeadingContainer {
    width: 90%;
    font-size: 1.25rem;
  }
  .aboutField {
    font-size: 1.5rem;
  }
}
