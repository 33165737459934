.servicesWrapper {
  flex-direction: column;
}

.servicesHeading {
  margin: 75px 10px 30px 10px;
  font-size: 4rem;
  text-align: center;
}

.servicesListWrapper {
  width: 100%;
  overflow: auto;
}
::-webkit-scrollbar {
  display: none;
}

.servicesList {
  width: 1100px;
  height: 465px;
  margin: 0 10px;
}

.serviceDiv {
  flex-direction: column;
  justify-content: space-between;
  background: #f6f7f8;
  width: 350px;
  height: 100%;
  border-radius: 0.5rem;
}

.serviceDiv:nth-of-type(2) {
  margin: 0 20px;
}

.service {
  font-size: 1.5rem;
  padding: 30px 50px 30px 40px;
  font-weight: 500;
}

.serviceDescription {
  padding: 0 50px 25px 40px;
}

.serviceImg {
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.shopBtn {
  color: white;
  font-size: 1rem;
  font-weight: bold;
  background: #0aad0a;
  padding: 15px;
  width: 360px;
  border-radius: 1rem;
  outline-style: none;
  border: none;
  margin: 50px;
  cursor: pointer;
}
.shopBtn:hover {
  background: #039b03;
}

@media (max-width: 1100px) {
  .servicesListWrapper {
    justify-content: flex-start;
  }
}

@media (max-width: 900px) {
  .servicesHeading {
    font-size: 3.5rem;
  }
}

@media (max-width: 550px) {
  .serviceDiv:nth-of-type(2) {
    margin: 0 10px;
  }

  .serviceDiv {
    width: 300px;
  }

  .shopBtn {
    width: 90%;
  }
}
