.heroWrapper1 {
  width: 100%;
  min-height: 90vh;
  background: #deedd6;
  position: relative;
  justify-content: flex-start;
  overflow: hidden;
  background-image: url("../../../public/banner1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.heroWrapper2 {
  width: 100%;
  min-height: 90vh;
  background: #deedd6;
  position: relative;
  justify-content: flex-start;
  overflow: hidden;
  background-image: url("../../../public/banner2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.heroWrapper3 {
  width: 100%;
  min-height: 90vh;
  background: #deedd6;
  position: relative;
  justify-content: flex-start;
  overflow: hidden;
  background-image: url("../../../public/banner3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.heroInner {
  margin-left: 150px;
  flex-direction: column;
  z-index: 10;
}

.headerText {
  letter-spacing: 0.5px;
  font-size: 3.5rem;
  color: #000;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 4rem;
  width: 60%;
  margin-top: 5px;
}

.heroInner p {
  font-size: 18px;
  margin: 25px 0;
}
.heroImage img {
  height: 100%;
}
.slogan {
  color: rgba(0, 0, 0, 0.877);
}

.btn {
  outline: none;
  border: none;
  background: #0aad0a;
  border-radius: 1rem;
  cursor: pointer;
  padding: 15px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  width: 48%;
}

.btn:hover {
  background: #039b03;
  cursor: pointer;
}

.btnGroup {
  display: flex;
  justify-content: space-between;
  width: 35%;
}

@media (max-width: 900px) {
  .heroImage {
    width: 100%;
  }
  .heroImage img {
    transform: rotate(-90deg) scale(1.2);
    height: 100vw;
    object-fit: cover;
    position: absolute;
    left: 10%;
    bottom: 10px;
  }
  .heroWrapper {
    align-items: flex-end;
  }

  .heroInner {
    margin: 50px 20px;
    overflow: hidden;
    width: 100%;
  }
  .inputLocation {
    margin-top: 20px;
    width: 100%;
  }
  .inputLocation input {
    width: 90%;
  }
  .slogan {
    display: none;
  }
  .dropDown {
    display: none;
  }
  .headerText {
    font-size: 3rem;
  }
}

@media (max-width: 550px) {
  .inputLocation {
    width: 100%;
  }
  .heroImage img {
    bottom: 160px;
  }
  .headerText {
    width: 80%;
    font-size: 2.5rem;
  }
  .btn {
    padding: 0.5rem 1rem;
    font-weight: bold;
    color: #fff;
    font-size: 0.5rem;
  }
  .btnGroup {
    display: flex;
    justify-content: space-between;
    width: 45%;
  }
}

@media (max-width: 400px) {
  .headerText {
    width: 80%;
    font-size: 2rem;
  }
}
